// CSS load
import './loader.scss';
// HTML load
import template from './loader.html'



import files from './files.js'
import { load } from './assets-loader.js'
// import logoLoader from './logo-loader.png'
import logoLoader from '../assets/images/main_logo_full.svg';





document.addEventListener('DOMContentLoaded', () => {
	document.querySelector('#root').insertAdjacentHTML('beforeend', template.replace('{{logoLoader}}', logoLoader));
	// document.querySelector( '#root' ).insertAdjacentHTML( 'beforeend' , template );

	// setTimeout(() => {
	Promise.all(files.map(o => load(o)))
	// }, 3500)
})