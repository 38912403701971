const filetypeToTag = {
  css: 'link',
  js: 'script'
}

export function load (url) {
  let start = new Date().getTime();

  const filetype = url.split('.').pop()
  const tag = filetypeToTag[filetype]
  return new Promise((resolve, reject) => {
    const element = document.createElement(tag)
    let parent = 'body'
    let attr = 'src'






    var xhr = new XMLHttpRequest;


    // We need to set different attributes depending on tag type
    switch (tag) {
      case 'script':
        element.async = false
        break
      case 'link':
        element.type = 'text/css'
        element.rel = 'stylesheet'
        attr = 'href'
        parent = 'head'
    }


    xhr.open( "GET" , url , true);
    xhr.onload = function (e) {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          element.text = xhr.response;

          let read_time = new Date().getTime() - start;

          // if ( read_time < 5000 ) {
          //   setTimeout(() => {
          //     document.head.appendChild(element);

          //     resolve(url);
          //   } , 5000 - read_time );

          // } else {
            document.head.appendChild(element);

            resolve(url);

          // }
          
        } else {
          alert("error");
          console.error(xhr.response);


          reject(url)
        }
      }
    };
    xhr.onprogress = function (e) {
      if (e.lengthComputable){

      }
        console.log( e , 'Loading JavaScript: ' + (100 * e.loaded / + e.total) + '%');
    };
    xhr.send();









    // Inject into document to kick off loading
    // element[attr] = url;
    // document.head.appendChild(element)
  })
}